import { WretchError } from 'wretch/resolver';
import { useToast } from 'ui';
import { useTranslations } from 'i11n';

export const useApiError = () => {
  const { toast } = useToast();

  const t = useTranslations();

  const handleError = (error: WretchError) => {
    const code = error.json.code || 'unexpected_error';

    toast({
      title: t(`errors.${code}.title`, error.json.metadata || {}),
      description: t(`errors.${code}.description`, error.json.metadata || {}),
    });
  };

  return {
    handleError,
  };
};
