import { httpClient } from '@/http/client';
import { SignInRequestBody, LoginUserResponseBody } from 'bff';
import { UserType } from 'database';

export const signInIDP = async (
  data: SignInRequestBody,
): Promise<LoginUserResponseBody> =>
  await httpClient
    .headers({
      'x-user-type': 'third inhouse_lawyer' satisfies `${UserType} ${UserType}`,
    })
    .post(data, '/v1/auth/identity/sign-in')
    .json();
