import { httpClient } from '@/http/client';
import { LoginUserRequestBody, LoginUserResponseBody } from 'bff';

export const loginUser = (
  data: LoginUserRequestBody,
): Promise<LoginUserResponseBody> =>
  httpClient
    .headers({
      'x-user-type': 'third inhouse_lawyer',
    })
    .post(data, '/v1/auth/login')
    .json();
