'use client';
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
} from 'ui';
import { SeparatorText } from '@/components/SeparatorText';
import { SignInWithGoogle } from '@/components/SignInWithGoogle';
import { useApiError } from '@/hooks/useApiError';
import { loginUser } from '@/lib/loginUser';
import { requiredFieldMessage } from '@/schema/claim';
import { zodResolver } from '@hookform/resolvers/zod';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { signInIDP } from '../lib/signInIDP';

const schema = z.object({
  email: z.string().email().min(1, requiredFieldMessage),
  password: z.string().min(1, requiredFieldMessage),
});

type FormData = z.infer<typeof schema>;

export const LoginUserForm = ({ onDone }: { onDone: () => void }) => {
  const [isLoading, setIsLoading] = useState(false);

  const { handleError } = useApiError();

  const router = useRouter();

  const handleSubmit = useCallback((data: FormData) => {
    setIsLoading(true);

    loginUser(data)
      .then((res) => {
        if (onDone) {
          onDone();
        }
      })
      .catch(handleError)
      .finally(() => setIsLoading(false));
  }, []);

  const form = useForm<FormData>({
    resolver: zodResolver(schema),
  });

  return (
    <>
      <div className='w-full'>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(handleSubmit)}
            className='w-full grid grid-cols-1 gap-4'
          >
            <FormField
              control={form.control}
              name='email'
              defaultValue=''
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />

            <FormField
              control={form.control}
              name='password'
              defaultValue=''
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel>Contraseña</FormLabel>
                    <FormControl>
                      <Input type='password' {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />

            <Link className='text-sm hover:underline' href='/forgot-password'>
              Olvidé mi contraseña
            </Link>

            <Button loading={isLoading} type='submit' className='mt-5'>
              Iniciar sesión
            </Button>
          </form>
        </Form>

        <SeparatorText>O también podés</SeparatorText>

        <SignInWithGoogle signIn={signInIDP} onDone={onDone} />
      </div>
    </>
  );
};
