'use client';
import React from 'react';
import { LoginUserForm } from './LoginUserForm';
import { RegisterLink } from './RegisterLink';
import { useRouter } from 'next/navigation';

export const Login = () => {
  const router = useRouter();

  return (
    <div className='space-y-4 w-full'>
      <LoginUserForm onDone={() => router.push('/')} />
      <RegisterLink />
    </div>
  );
};
